import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Obfuscate from "react-obfuscate";

import clippy from "../../content/assets/icons/clippy.png"
import styles from "./Bio.module.styl"

export default function Bio() {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/headshot.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            title
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  return (
    <div className={ styles.bio }>
      <dl className={ styles.links }>
        <dt>Email</dt>
        <dd>You can best reach Rany at <Obfuscate email="rany@feldberlin.com" /></dd>
        <dt>GPG Key</dt>
        <dd>
          <p>You can send Rany encrypted email. Click through the fingerprint to obtain the public GPG key: </p>
          <br />

          <p className={ styles.key }>
            <a href="https://keybase.io/purzelrakete/pgp_keys.asc">
              F783 4A52 C22D A294 1693<br />
              F94C 2BF2 9472 2BD2 3AC7
            </a>
          </p>
        </dd>
        <dt>On the web</dt>
        <dd>Connect on <a href="http://linkedin.com/in/ranykeddo">LinkedIn</a>, or follow Rany on the official <a href="http://twitter.com/feldberlin">@feldberlin</a> or personal <a href="http://twitter.com/purzelrakete">@purzelrakete</a> accounts.
        </dd>
        <dd>Also have a look at Rany's publications on <a href="https://scholar.google.com/citations?user=3mkaJQ8AAAAJ">google scholar</a>.</dd>
      </dl>
      <div className={ styles.body }>
        <div className={ styles.head }>
          <div>
            <Image fixed={data.avatar.childImageSharp.fixed} />
          </div>
          <div className={ styles.name }>
            <h2>{author.name}</h2>
            <p>{author.title}</p>
          </div>
        </div>
        <div className={ styles.vita }>
          <p>
            Rany is the founder and managing director of FELD. As a principal
            consultant he works with technology companies to establish
            or develop effective data science practices. He has launched
            products, teams and platforms for his clients.
          </p>
          <p>
            Rany applies software engineering, machine learning and
            data science disciplines to business problems. As an architect, he brings
            a decade of experience with machine learning ops and data platform
            design to his client work. As a research engineer,
            he designs and builds custom recommender systems, search systems
            and music information retrieval systems that have a demonstrated
            impact on company kpis.
          </p>
          <p>
            Rany designed and built the music recommender system used by
            SoundCloud, driving the listen behaviour of hundreds of millions of
            users around the world. He built and operated <a href="https://techcrunch.com/2016/06/22/soundcloud-improves-music-discovery-with-new-suggested-tracks-feature/">Suggested Tracks</a>,&nbsp;
            <a href="https://www.vice.com/en_us/article/pg7y5y/soundcloud-the-upload">The Upload</a>,&nbsp;
            and related tracks, which had an impact on popular culture, even&nbsp;
            <a href="http://www.mtv.com/news/2752585/rory-fresco-soundcloud-kanye-west-epic-records/">launching artist careers</a>.
            He has worked with financial institutions like Deutsche Kreditbank
            and Börse Stuttgart, as well as various clients in the music space.
          </p>
          <p>
            Before this, Rany worked for startups in media, as well as
            financial institutions like American Express Sharepeople and the
            SEB Bank.
          </p>
          <p>
            His open source <a href="https://github.com/soundcloud/lhm">projects</a> have been used by companies like GitHub and Kickstarter.
          </p>
        </div>
        <div className={ styles.cv }>
          <a href="/cv/rany-keddo">
            <img src={clippy} alt="cv" />
            Curriculum Vitae
          </a>
        </div>
      </div>
    </div>
  )
}
