/* About FELD
 *
 */

import React from 'react'

import Bio from '../../components/Bio'
import Layout from "../../components/Layout"
import styles from "./index.module.styl"
import SEO from '../../components/seo'

const title = 'FELD Berlin. Team'
const description = `
  FELD was started by Rany after 7 years of creating successful
  machine learning systems for hundreds of millions of users at
  SoundCloud. FELD can now share this experience with you – hire us and
  get a decade of industry experience working for you.  We have launched
  machine learning labs in banks, and have architected and implemented
  high impact data platforms for banking clients and implemented music
  machine learning systems.
`

class About extends React.Component {
  render() {
    return (
      <Layout location={ this.props.location } footer={ "newsletter" }>
        <SEO title={ title } description={ description } />
        <div className={ styles.about }>
          <h1>About FELD</h1>
          <p>{ description }</p>
          <Bio />
       </div>
      </Layout>
    )
  }
}

export default About
